<template>
  <div>
    <nav-bar v-if="showBackBtn" @onBack="handleBack" />
    <div v-if="isClose" class="category-task">
      <h3 class="task-name">{{ skuTaskName }}</h3>
      <div class="task-end-time">{{ effectiveTime }}</div>
      <div class="skuTaskUpload">
        <div class="skuTaskUploadFile">
          <div class="category">
            <table border cellpadding="8">
              <tr>
                <th>品类名称</th>
                <th>数量</th>
                <th>操作</th>
              </tr>
              <tr v-for="(item, index) in categories" :key="index">
                <td class="cate">
                  <div class="cate-box">
                    <span class="order">{{ index + 1 }}</span>
                    <div
                      class="cate-input"
                      :class="[item.isConfirm ? 'no-border' : '']"
                      @click.stop
                    >
                      <span v-if="item.isConfirm">{{ item.categoryName }}</span>
                      <template v-else>
                        <input
                          v-model="item.categoryName"
                          type="text"
                          @focus.stop="handleFocus(index, $event)"
                          @input="handleInput"
                        />
                        <div
                          v-if="index === showListIndex && showList.length"
                          class="cate-list"
                        >
                          <div
                            v-for="item in showList"
                            :key="item.categoryName"
                            class="list-item"
                            @click.stop="handleSelect(index, item.categoryName)"
                          >
                            {{ item.categoryName }}
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </td>
                <td class="count-input">
                  <span v-if="item.isConfirm">{{ item.count }}</span>
                  <input v-else v-model="item.count" type="number" />
                </td>
                <td class="operation">
                  <div v-show="!finishSubmit" class="operation-box">
                    <template v-if="item.isConfirm">
                      <van-button
                        type="danger"
                        size="mini"
                        @click="updateCate(index, item)"
                        >修改</van-button
                      >
                      <van-button
                        type="default"
                        size="mini"
                        @click="delCate(index)"
                        >删除</van-button
                      >
                    </template>
                    <van-button
                      v-else-if="item.isConfirm === false"
                      type="danger"
                      size="mini"
                      @click="confirmCate(index, item)"
                    >
                      确定
                    </van-button>
                    <van-button
                      v-else
                      type="danger"
                      size="mini"
                      @click="addCate(item)"
                      >添加</van-button
                    >
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="submit">
        <span v-if="finishSubmit">已提交成功</span>
        <van-button
          v-else
          class="skuTaskSubmit"
          type="danger"
          :loading="loading"
          loading-text="提交中..."
          @click="handleSubmit"
        >
          点我提交!
        </van-button>
        <div class="feedback">如无法完成任务，请<span class="linkText" @click="clickFeedback">点击此处</span>反馈</div>
      </div>
    </div>
    <van-empty v-else description />
  </div>
</template>

<script>
import NavBar from "../components/nav-bar.vue";

import {
  getTaskDetails,
  getListSkuCategoriesCurrentDay,
  executeSkuCountTask,
} from "@/apis";
import { formatDate } from "@/tools/date";
import { getUrlParams, userAccessValid } from "../shared";
import { Toast, Dialog } from "vant";
import backListMixins from "../mixins/back-list";

const COMPANY_ID = 81;
export default {
  name: "CategoryTask",
  components: {
    NavBar,
  },
  mixins: [backListMixins],
  data() {
    return {
      // tips: '请从左至右按顺序输入小程序中的商品分类',
      urlParams: {},
      skuTaskName: "",
      effectiveTime: "",
      loading: false,
      companyId: COMPANY_ID,
      isClose: true,
      taskEndTime: 0,
      categories: [{ categoryName: "", count: null }],
      finishSubmit: false,
      showListIndex: null,
      showList: [],
      cateList: [],
    };
  },
  async created() {
    this.urlParams = getUrlParams(this.$route.query);
    await this.getUserAccessValid();
  },
  mounted() {
    document.addEventListener("click", () => {
      if (this.showListIndex !== null) {
        this.showListIndex = null;
      }
    });
    const cacheList = localStorage.getItem(
      `${this.urlParams.userTaskId}_cache`
    );
    if (cacheList) {
      this.categories = [...JSON.parse(cacheList), ...this.categories];
    }
  },
  methods: {
    // h5鉴权
    async getUserAccessValid() {
      try {
        const isValid = await userAccessValid(this.urlParams);
        this.isClose = isValid;
        if (isValid) {
          await this.getJobDetails();
          this.getListSkuCategoriesCurrentDay();
        }
      } catch {
        Toast.fail("鉴权失败");
      }
    },
    // 预加载当天基线
    async getListSkuCategoriesCurrentDay() {
      try {
        const res = await getListSkuCategoriesCurrentDay({
          cityId: this.urlParams.cityId,
          companyId: this.companyId,
        });
        if (res && res.data && res.data.length) {
          this.cateList = [...res.data];
          // this.tips = '请核对小程序中的商品分类'
        }
      } catch {
        Toast.fail("预加载品类异常！");
      }
    },
    // 获取任务详情
    async getJobDetails() {
      try {
        const { userTaskId, status } = this.urlParams;
        let res = await getTaskDetails({ userTaskId, status: status ? +status : undefined });
        if (res) {
          let data = res.data || {};
          if (res.code !== 0)
            return Toast.fail(res.msg || "获取任务详情接口返回错误");
          let time = formatDate(
            new Date(data.jobEndTime),
            "yyyy-MM-dd HH:mm:ss"
          );
          this.skuTaskName = data.name;
          this.effectiveTime = `提交截止时间:${time}`;
        }
      } catch (err) {
        Toast.fail(err.message || "获取任务详情接口请求错误");
      }
    },
    handleFocus(index, e) {
      this.showListIndex = index;
      const val = e.target.value;
      this.showList = this.cateList.filter(
        (item) => item.categoryName && item.categoryName.includes(val)
      );
    },
    handleInput(e) {
      const val = e.target.value;
      this.showList = this.cateList.filter(
        (item) => item.categoryName && item.categoryName.includes(val)
      );
    },
    handleSelect(index, val) {
      this.categories[index].categoryName = val;
      this.categories = this.categories.map((item, i) => {
        if (index === i) {
          item.categoryName = val;
        }
        return item;
      });
      this.showListIndex = null;
    },
    addCate(item) {
      if (item.categoryName && item.count) {
        if (
          /[\u4e00-\u9fa5]/.test(item.categoryName) &&
          /^[1-9]\d*$/.test(item.count)
        ) {
          item.isConfirm = true;
          const newCate = { categoryName: "", count: "" };
          localStorage.setItem(
            `${this.urlParams.userTaskId}_cache`,
            JSON.stringify(this.categories)
          );
          this.categories = [...this.categories, newCate];
        } else {
          Toast.fail("请完填写合法的品类数量信息");
        }
      } else {
        Toast.fail("请完整填写品类数量信息");
      }
    },
    updateCate(index) {
      this.categories = this.categories.map((item, i) => {
        if (i === index) {
          item.isConfirm = false;
        }
        return item;
      });
    },
    confirmCate(index) {
      this.categories = this.categories.map((item, i) => {
        if (i === index) {
          item.isConfirm = true;
        }
        return item;
      });
      localStorage.setItem(
        `${this.urlParams.userTaskId}_cache`,
        JSON.stringify(
          this.categories.filter(
            (item) => typeof item.isConfirm !== "undefined"
          )
        )
      );
    },
    delCate(index) {
      if (this.categories.length === 1) return;
      Dialog.confirm({ title: "确定删除？" })
        .then(() => {
          this.categories = this.categories.filter((item, i) => i !== index);
          localStorage.setItem(
            `${this.urlParams.userTaskId}_cache`,
            JSON.stringify(
              this.categories.filter(
                (item) => typeof item.isConfirm !== "undefined"
              )
            )
          );
        })
        .catch(() => {});
    },
    handleSubmit() {
      if (this.categories.length < 1) {
        Toast("请填写品类信息，再提交！");
        return;
      }
      if (
        this.categories.some(
          (item) => (item.categoryName || item.count) && !item.isConfirm
        )
      ) {
        Toast("请确认完整品类信息，再提交！");
        return;
      }

      Dialog.confirm({ title: "确定提交？", message: "提交后，数据不能修改" })
        .then(() => {
          this.confirmSubmit();
        })
        .catch(() => {});
    },
    async confirmSubmit() {
      this.loading = true;
      const skuCategoryList = this.categories
        .filter((item) => item.categoryName)
        .map((item, index) => {
          return {
            order: index + 1,
            skuCountCollect: +item.count,
            categoryName: item.categoryName,
          };
        });
      const { jobId, userTaskId, cityId } = this.urlParams;
      try {
        const res = await executeSkuCountTask({
          jobId,
          userTaskId,
          companyId: this.companyId, // DD的companyId
          cityId,
          skuCategoryList,
        });
        this.loading = false;
        if (res) {
          this.loading = false;
          let msg = "恭喜您提交成功！";
          let confirmButtonText = "关闭";
          if (res.code !== 0) {
            msg = res.msg || "抱歉提交失败！";
            confirmButtonText = "我再试试";
          }
          await Dialog["alert"]({
            message: msg,
            confirmButtonText,
          });
          if (res.code === 0) {
            this.finishSubmit = true;
            this.categories = this.categories.filter(
              (item) => item.categoryName && item.count
            );
            localStorage.clear();
          }
        }
      } catch {
        Toast.fail("任务提交异常！");
      }
    },
    clickFeedback() {
      this.$router.push({ path: '/abnormalFeedback'+ location.search});
    },
  },
};
</script>

<style lang="less" scoped>
.task-name {
  padding: 0 20px;
  font-size: 16px;
  text-align: center;
}
.task-end-time {
  font-size: 14px;
}
.dialog-content-table {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
  table {
    width: 80%;
    border-collapse: collapse;
    th {
      background: #f2f6fc;
    }
    tr {
      text-align: center;
    }
  }
}
.category-task {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  font-size: 16px;
  .skuTaskUpload {
    width: calc(100% - 30px);
    padding: 15px;
    margin-top: 25px;
    font-size: 14px;
    background-color: #e4e7ed;
    border-radius: 20px;
    .skuTaskUploadFile {
      .toolTip {
        display: inline-block;
        margin-bottom: 20px;
      }
    }
  }
  .category {
    background: #fff;
    table {
      width: 100%;
      border-collapse: collapse;
      border-color: #c0c4cc;
      th {
        background: #eee;
      }
      .cate {
        .cate-box {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .order {
          display: inline-block;
          width: 15px;
          margin-right: 5px;
          text-align: center;
        }
        input {
          width: 92%;
          padding: 0;
          padding-left: 5px;
          line-height: 20px;
          border: none;
        }
        .cate-input {
          position: relative;
          display: inline-block;
          width: 75%;
          border: 1px solid #c0c4cc;
          &.no-border {
            border-color: transparent;
          }

          .cate-list {
            position: absolute;
            left: -1px;
            z-index: 999;
            width: 100%;
            border: 1px solid #c0c4cc;
            .list-item {
              padding-left: 5px;
            }
            .list-item:not(:last-child) {
              border-bottom: 1px solid #c0c4cc;
            }
          }
        }
      }
      .count-input {
        width: 30%;
        text-align: center;
        input {
          width: 80%;
          padding: 0;
          padding-left: 5px;
          line-height: 20px;
          border: none;
          border: 1px solid #c0c4cc;
        }
      }
      .operation {
        width: 35%;
        .operation-box {
          display: flex;
          align-items: center;
        }
        .van-button--mini {
          width: 45%;
        }
      }
    }
  }
  .submit {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20%;
    .skuTaskSubmit {
      width: 60%;
      color: #fff;
      background: #f56c6c;
      border-color: #f56c6c;
      border-radius: 16px;
    }
    .feedback {
      padding-top: 10px;
      font-size: 14px;
      text-align: center;
      .linkText{
        font-weight: bold;
        color: #3e7bf3
      }
    }
  }
}
</style>
